<template>
  <b-card no-body>
    <b-card-header header-tag="header" role="tab" @click="toggleCollapse">
      <h5 class="mb-0 d-flex justify-content-between align-items-center">
        {{ header }}
        <b-icon :icon="isCollapsed ? 'chevron-down' : 'chevron-up'" />
      </h5>
    </b-card-header>
    <b-collapse :id="id" v-model="isCollapsed" accordion="my-accordion" role="tabpanel">
      <b-card-body>
        <div v-for="(section, index) in instructionalText.sections" :key="index">
          <strong>{{ section.header }}</strong>
          <div v-if="section.generic" v-html="section.generic" />
          <ul>
            <li v-for="(item, itemIndex) in section.items" :key="itemIndex">
              <div v-html="item" />
            </li>
          </ul>
        </div>
      </b-card-body>
      <router-link :to="{ name: 'monitoring-overview' }" class="home-link">Back to Home</router-link>
    </b-collapse>
  </b-card>
</template>


<script>
export default {
  name: "MonitoringInstructionalAccordion",
  components: {},
  props: {
    header: {
      type: String,
      required: true,
    },
    instructionalText: {
      type: Object,
      required: true,
      validator: (value) => {
        return (
          value &&
          typeof value === "object" &&
          value.sections &&
          Array.isArray(value.sections)
        )
      },
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCollapsed: true,
    }
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed
    },
  },
}
</script>

<style scoped>
.home-link {
  margin-left: 1rem;
}
</style>
