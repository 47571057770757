var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "no-identities" },
    [
      _vm._v(
        "\n  You have not setup any " +
          _vm._s(_vm.singular(_vm.identityType)) +
          " Identities for monitoring.\n  "
      ),
      !_vm.modelSolution
        ? _vm._t("default")
        : _c(
            "div",
            { staticClass: "add-identity-button" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "success" },
                  on: { click: _vm.emitShowModal },
                },
                [_vm._v("Add " + _vm._s(_vm.identityType))]
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }