var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        {
          attrs: { "header-tag": "header", role: "tab" },
          on: { click: _vm.toggleCollapse },
        },
        [
          _c(
            "h5",
            {
              staticClass:
                "mb-0 d-flex justify-content-between align-items-center",
            },
            [
              _vm._v("\n      " + _vm._s(_vm.header) + "\n      "),
              _c("b-icon", {
                attrs: {
                  icon: _vm.isCollapsed ? "chevron-down" : "chevron-up",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: { id: _vm.id, accordion: "my-accordion", role: "tabpanel" },
          model: {
            value: _vm.isCollapsed,
            callback: function ($$v) {
              _vm.isCollapsed = $$v
            },
            expression: "isCollapsed",
          },
        },
        [
          _c(
            "b-card-body",
            _vm._l(_vm.instructionalText.sections, function (section, index) {
              return _c("div", { key: index }, [
                _c("strong", [_vm._v(_vm._s(section.header))]),
                section.generic
                  ? _c("div", {
                      domProps: { innerHTML: _vm._s(section.generic) },
                    })
                  : _vm._e(),
                _c(
                  "ul",
                  _vm._l(section.items, function (item, itemIndex) {
                    return _c("li", { key: itemIndex }, [
                      _c("div", { domProps: { innerHTML: _vm._s(item) } }),
                    ])
                  }),
                  0
                ),
              ])
            }),
            0
          ),
          _c(
            "router-link",
            {
              staticClass: "home-link",
              attrs: { to: { name: "monitoring-overview" } },
            },
            [_vm._v("Back to Home")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }