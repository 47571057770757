<template>
  <div class="no-identities">
    You have not setup any {{ singular(identityType) }} Identities for monitoring.
    <slot v-if="!modelSolution" />

    <div v-else class="add-identity-button">
      <b-button variant="success" @click="emitShowModal">Add {{ identityType }}</b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    identityType: String,
    modelSolution: Boolean,
  },
  methods: {
    emitShowModal() {
      this.$emit('add-identity')
    },
    singular(identityType) {
      switch (identityType) {
        case "Keywords": 
          return "Keyword"
        case "trademarks":
          return "Trademark"
        case "companies":
          return "Company"
        case "domains":
          return "Domain"
      }
    },
  },
}
</script>

<style lang="scss">
.no-identities {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  border-radius: 0.3em;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 8em;
  padding-right: 8em;

  .add-identity-button {
    padding-top: 2em;
  }
}
</style>
